import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React, { Component, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import * as util from "../helper/utils";

import Button from "../components/Button";
import useAnalyzeModal from "../hooks/useAnalyzeModal";
import Modal from "../components/Modal";
import useOdkModal from "../hooks/useOdkModal";

import { useTranslation } from "react-i18next";

const theme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        thumb: {
          color: "#E59866",
        },
        track: {
          color: "#DC7633",
        },
        rail: {
          color: "#FBEEE6",
        },
        markLabel: {
          color: "black",
          fontSize: "11px",
          fontWeight: "bold",
        },
      },
    },
  },
});

const AreaCircle = ({ areaValue }) => {
  const width = 100;
  const height = 100;
  const padding = 10;

  const radius = (width - padding * 2) / 2;

  return (
    <svg width={width + padding * 2} height={height + padding * 2}>
      <circle
        cx={(width + padding * 2) / 2}
        cy={(height + padding * 2) / 2}
        r={radius}
        fill="#ADD8E6"
      />
      <text
        x="50%"
        y="50%"
        dy="-7"
        alignmentBaseline="middle"
        textAnchor="middle"
        fontSize="10"
        fontFamily="Arial"
      >
        {`${twoDecimalFormatter(areaValue)}`}
      </text>
      <text
        x="50%"
        y="50%"
        dy="7"
        alignmentBaseline="middle"
        textAnchor="middle"
        fontSize="10"
        fontFamily="Arial"
      >
        acres
      </text>
    </svg>
  );
};

const twoDecimalFormatter = (number) => {
  return number.toFixed(2);
};

const WaterBodiesModal = () => {
  const kharif_18_19 = Number(sessionStorage.getItem("k_18-19"));
  const kharif_rabi_18_19 = Number(sessionStorage.getItem("kr_18-19"));
  const kharif_rabi_zaid_18_19 = Number(sessionStorage.getItem("krz_18-19"));

  const kharif_19_20 = Number(sessionStorage.getItem("k_19-20"));
  const kharif_rabi_19_20 = Number(sessionStorage.getItem("kr_19-20"));
  const kharif_rabi_zaid_19_20 = Number(sessionStorage.getItem("krz_19-20"));

  const kharif_20_21 = Number(sessionStorage.getItem("k_20-21"));
  const kharif_rabi_20_21 = Number(sessionStorage.getItem("kr_20-21"));
  const kharif_rabi_zaid_20_21 = Number(sessionStorage.getItem("krz_20-21"));

  const kharif_21_22 = Number(sessionStorage.getItem("k_21-22"));
  const kharif_rabi_21_22 = Number(sessionStorage.getItem("kr_21-22"));
  const kharif_rabi_zaid_21_22 = Number(sessionStorage.getItem("krz_21-22"));

  const kharif_22_23 = Number(sessionStorage.getItem("k_22-23"));
  const kharif_rabi_22_23 = Number(sessionStorage.getItem("kr_22-23"));
  const kharif_rabi_zaid_22_23 = Number(sessionStorage.getItem("krz_22-23"));

  const isOpen = useAnalyzeModal((state) => state.isOpen);
  const onClose = useAnalyzeModal((state) => state.onClose);

  const onOpenOdk = useOdkModal((state) => state.onOpen);
  const onSetState = useOdkModal((state) => state.onSetState);

  const { t } = useTranslation();

  // area
  var area = Number(sessionStorage.getItem("area_ored"));
  if (!isNaN(area)) {
    area = area / 4047;
  } else {
    console.error("Area value is not a number");
  }

  const data = [
    {
      year: "2018-2019",
      Kharif: kharif_18_19,
      "Kharif-Rabi": kharif_rabi_18_19,
      "Kharif-Rabi-Zaid": kharif_rabi_zaid_18_19,
      Area: area,
    },
    {
      year: "2019-2020",
      Kharif: kharif_19_20,
      "Kharif-Rabi": kharif_rabi_19_20,
      "Kharif-Rabi-Zaid": kharif_rabi_zaid_19_20,
      Area: area,
    },
    {
      year: "2020-2021",
      Kharif: kharif_20_21,
      "Kharif-Rabi": kharif_rabi_20_21,
      "Kharif-Rabi-Zaid": kharif_rabi_zaid_20_21,
      Area: area,
    },
    {
      year: "2021-2022",
      Kharif: kharif_21_22,
      "Kharif-Rabi": kharif_rabi_21_22,
      "Kharif-Rabi-Zaid": kharif_rabi_zaid_21_22,
      Area: area,
    },
    {
      year: "2022-2023",
      Kharif: kharif_22_23,
      "Kharif-Rabi": kharif_rabi_22_23,
      "Kharif-Rabi-Zaid": kharif_rabi_zaid_22_23,
      Area: area,
    },
  ];

  const [selectedYear, setSelectedYear] = useState(data[0].year);

  const handleYearChange = (event, newValue) => {
    setSelectedYear(data[newValue].year);
  };

  const filteredData = data.filter((d) => d.year === selectedYear);

  const marks = data.map((entry, index) => ({
    value: index,
    label: entry.year,
  }));

  const handleWBFeedbackClick = () => {
    let redirectState = {
      screen_code: "feedback_wb",
      next_screen: "",
    };

    onSetState(redirectState);

    onOpenOdk();
  };

  const bodyContent = (
    <div style={barGraphContainerStyle}>
      <div style={graphAndSliderContainerStyle}>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={filteredData}>
            {/* ... Rest of your BarChart code ... */}
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis
              label={{
                value: "% of area with water",
                angle: -90,
                position: "Left",
              }}
              domain={[0, 100]}
            />
            <Tooltip formatter={twoDecimalFormatter} />
            <Legend verticalAlign="top" height={36} />
            <Bar dataKey="Kharif" fill="#E38627" name="Kharif" />
            <Bar dataKey="Kharif-Rabi" fill="#C13C37" name="Rabi" />
            <Bar dataKey="Kharif-Rabi-Zaid" fill="#6A2135" name="Zaid" />
          </BarChart>
        </ResponsiveContainer>
        {/* <div style={textContainerStyle}>
                    Pattern over the years
                </div> */}
        <ThemeProvider theme={theme}>
          <CustomSlider handleYearChange={handleYearChange} marks={marks} />
        </ThemeProvider>

        {filteredData.length > 0 && (
          <AreaCircle areaValue={filteredData[0].Area} />
        )}
      </div>
      <div style={textContainerStyle}>
        <p>{t("info_swb_modal_1")}</p>
      </div>

      {/* Feedback button at the end */}
      <div style={centerStyle}>
        {/* <button className="footer-button" onClick={handleWBFeedbackClick}>
                    Provide Feedback
                </button> */}
        <Button label={"Provide Feedback"} onClick={handleWBFeedbackClick} />
      </div>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={"Water Bodies Analysis Graph"}
      body={bodyContent}
    />
  );
};

const CustomSlider = ({ handleYearChange, marks, selectedYear }) => {
  return (
    <Box
      sx={{
        width: 300,
        margin: "auto",
        backgroundColor: "#D6D4C8",
        borderRadius: "20px",
        padding: "20px",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Slider
        aria-label="Years"
        defaultValue={4}
        valueLabelDisplay="auto"
        step={null}
        marks={marks}
        max={marks.length - 1}
        onChange={handleYearChange}
        color="primary"
        sx={{
          color: "#795664",
          "& .MuiSlider-thumb": {
            borderRadius: "50%",
            width: 20,
            height: 20,
            backgroundColor: "#795664",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
            "&:hover": {
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.3)",
            },
          },
          "& .MuiSlider-rail": {
            height: 6,
            borderRadius: 4,
            backgroundColor: "#795664",
          },
          "& .MuiSlider-track": {
            height: 6,
            borderRadius: 4,
            backgroundColor: "#795664",
          },
        }}
      />
    </Box>
  );
};

export default WaterBodiesModal;

const graphAndSliderContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
};

const barGraphContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "auto",
  width: "auto",
};

const textContainerStyle = {
  textAlign: "left",
  fontSize: "13px",
  paddingLeft: "20px",
  paddingRight: "20px",
  marginBottom: "10px",
};

const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "20px",
  padding: "20px",
  marginBottom: "20px",
};
