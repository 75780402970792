import React, { useState } from 'react';
import {
  Line,
  LineChart,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import useAnalyzeModal from "../hooks/useAnalyzeModal";
import Modal from "../components/Modal";
import useOdkModal from "../hooks/useOdkModal";
import { useTranslation } from "react-i18next";

const theme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        thumb: { color: "#E59866" },
        track: { color: "#DC7633" },
        rail: { color: "#FBEEE6" },
        markLabel: {
          color: "black",
          fontSize: "11px",
          fontWeight: "bold",
        },
      },
    },
  },
});

const AgricultureModal = () => {
  const isOpen = useAnalyzeModal((state) => state.isOpen);
  const onClose = useAnalyzeModal((state) => state.onClose);
  const onOpenOdk = useOdkModal((state) => state.onOpen);
  const onSetState = useOdkModal((state) => state.onSetState);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const years = [2016, 2017, 2018, 2019, 2020, 2021, 2022];

  // Helper function to get session storage data
  const getSessionData = (key, defaultValue = 0) => {
    return Number(sessionStorage.getItem(key)) || defaultValue;
  };

  // Process drought and dry spell data
  const processedData = years.reduce((acc, year) => {
    const droughtData = String(sessionStorage.getItem(`drlb_${year}`)).match(/\d+/g) || [];
    const droughtCounts = droughtData.reduce((counts, val) => {
      const num = Number(val);
      if (num === 1) counts.mild++;
      if (num === 2) counts.moderate++;
      if (num === 3) counts.severe++;
      return counts;
    }, { mild: 0, moderate: 0, severe: 0 });

    acc[year] = {
      ...droughtCounts,
      dryspell: getSessionData(`drysp_${year}`)
    };
    return acc;
  }, {});

  // Process cropping data
  const croppingData = years.map((year, index) => {
    const suffix = index === 0 ? "" : `_${index}`;
    const haPrefix = index === 0 ? "ha" : index;

    const single_kharif = getSessionData(`single_k${haPrefix}`);
    const single_non = getSessionData(`single_n${suffix}`);
    const double = getSessionData(`doubly_c${suffix}`);
    const triple = getSessionData(`triply_c${suffix}`);
    const total_area = getSessionData("total_crop");

    const single_total = ((single_kharif + single_non) / total_area) * 100;
    const double_percent = (double / total_area) * 100;
    const triple_percent = (triple / total_area) * 100;

    return {
      year: year.toString(),
      single: single_total,
      double: double_percent,
      triple: triple_percent,
      uncropped: 100 - (single_total + double_percent + triple_percent),
      intensity: getSessionData(`cropping${suffix}`)
    };
  });

  // Prepare chart data
  const droughtData = years.map(year => ({
    year: year.toString(),
    ...processedData[year]
  }));

  const [selectedYear, setSelectedYear] = useState(years[0].toString());

  const handleYearChange = (event, newValue) => {
    setSelectedYear(droughtData[newValue].year);
  };

  const marks = years.map((year, index) => ({
    value: index,
    label: year.toString(),
  }));

  const filteredDroughtData = droughtData.filter(d => d.year === selectedYear);
  const filteredCroppingData = croppingData.filter(d => d.year === selectedYear);

  const twoDecimalFormatter = (number) => number.toFixed(2);

  const handleAgriFeedbackClick = () => {
    onSetState({
      screen_code: "feedback_agri",
      next_screen: "",
    });
    onOpenOdk();
  };

  const bodyContent = (
    <div style={barGraphContainerStyle}>
      <div style={graphAndSliderContainerStyle}>
        <h2 style={headerStyle}>Drought and Dry Spell Frequency</h2>
        <ResponsiveContainer width="100%" height={250}>
          <BarChart
            data={filteredDroughtData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis
              label={{
                value: "Frequency (# weeks)",
                angle: -90,
                position: "Left",
              }}
              domain={[0, 52]}
            />
            <Tooltip formatter={twoDecimalFormatter} />
            <Legend align="center" verticalAlign="top" height={36} />
            <Bar dataKey="mild" fill="#F4D03F" name="Mild Drought" />
            <Bar dataKey="moderate" fill="#EB984E" name="Moderate Drought" />
            <Bar dataKey="severe" fill="#E74C3C" name="Severe Drought" />
            <Bar dataKey="dryspell" fill="#8884d8" name="Dry Spell" />
          </BarChart>
        </ResponsiveContainer>

        <div style={textContainerStyle}>
          <p>{t("info_agri_modal_1")}</p>
        </div>

        <ThemeProvider theme={theme}>
          <Box
            sx={{
              width: 300,
              margin: "auto",
              backgroundColor: "#D6D4C8",
              borderRadius: "20px",
              padding: "20px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Slider
              aria-label="Years"
              defaultValue={6}
              valueLabelDisplay="auto"
              step={null}
              marks={marks}
              max={marks.length - 1}
              onChange={handleYearChange}
              color="primary"
              sx={{
                color: "#795664",
                "& .MuiSlider-thumb": {
                  borderRadius: "50%",
                  width: 20,
                  height: 20,
                  backgroundColor: "#795664",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                  "&:hover": {
                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.3)",
                  },
                },
                "& .MuiSlider-rail": {
                  height: 6,
                  borderRadius: 4,
                  backgroundColor: "#795664",
                },
                "& .MuiSlider-track": {
                  height: 6,
                  borderRadius: 4,
                  backgroundColor: "#795664",
                },
              }}
            />
          </Box>
        </ThemeProvider>

        <div style={{ width: "100%", height: "250px", marginTop: "20px" }}>
          <h2 style={headerStyle}>Cropping Pattern</h2>
          <ResponsiveContainer width="100%" height={250}>
            <BarChart
              data={filteredCroppingData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis
                label={{
                  value: "Cropping Patterns (%)",
                  angle: -90,
                  position: "Left",
                }}
              />
              <Tooltip formatter={twoDecimalFormatter} />
              <Legend
                align="center"
                verticalAlign="bottom"
                layout="horizontal"
                wrapperStyle={{ lineHeight: "20px" }}
              />
              <Bar dataKey="single" stackId="a" fill="#57ad2b" name="Single Cropping" />
              <Bar dataKey="double" stackId="a" fill="#e68600" name="Double Cropping" />
              <Bar dataKey="triple" stackId="a" fill="#b3561d" name="Triple Cropping" />
              <Bar dataKey="uncropped" stackId="a" fill="#A9A9A9" name="Uncropped Area" />
            </BarChart>
          </ResponsiveContainer>

          <h2 style={headerStyle}>Cropping Intensity</h2>
          <ResponsiveContainer width="100%" height={250}>
            <LineChart
              data={croppingData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis
                label={{
                  value: "Cropping Intensity (%)",
                  angle: -90,
                  position: "Left",
                }}
              />
              <Tooltip formatter={twoDecimalFormatter} />
              <Line type="monotone" dataKey="intensity" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>

          <div style={textContainerStyle}>
            <p>{t("info_agri_modal_2")}</p>
          </div>

          <div style={centerStyle}>
            <Button
              label="Provide Feedback"
              onClick={handleAgriFeedbackClick}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Agriculture Analysis Graph"
      body={bodyContent}
    />
  );
};

const barGraphContainerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "left",
  alignItems: "left",
  overflowY: "auto",
  maxHeight: "80vh",
  marginTop: "25px",
};

const graphAndSliderContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  justifyContent: "left",
  padding: "15px",
};

const textContainerStyle = {
  textAlign: "left",
  padding: "0px",
  fontSize: "13px",
};

const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "20px",
  padding: "20px",
  marginBottom: "20px",
};

const headerStyle = {
  textAlign: "left",
  width: "100%",
  marginBottom: "20px",
};

export default AgricultureModal;
